import { APP_INITIALIZER, ApplicationConfig } from '@angular/core';
import { provideRouter, withEnabledBlockingInitialNavigation, withRouterConfig } from '@angular/router';

import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideToastr } from 'ngx-toastr';
import { AuthInterceptor } from './core/auth.Interceptor';
import { ContextService } from './services/context-service';

export function initializeApp(contextService: ContextService) {  
  return (): Promise<any> =>{
    contextService.SetupDefaults();   
    return;
  }   
}


export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(withFetch(),
    withInterceptors([AuthInterceptor])),
    provideRouter(routes,
      withEnabledBlockingInitialNavigation(),
      withRouterConfig({ onSameUrlNavigation: 'reload' })),
    provideClientHydration(),
    provideAnimations(), // required animations providers
    provideToastr({
      timeOut: 2000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      closeButton: true,
      enableHtml: true,
      maxOpened: 5,
      newestOnTop: false,
      progressAnimation: 'decreasing',
      progressBar: true
    }), // Toastr providers
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [ContextService],
      multi: true,
    }
    ]
};
